<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Грантовые конкурсы, субсидии</h1>
      <div class="content__group">
        <button @click="onExport" type="button" class="link">
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word</span>
        </button>
        <router-link v-if="userRole === 'admin'" :to="{ name: 'grant-stat' }" class="link link--right" type="button">
          <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
          <span>Статистика</span>
        </router-link>
      </div>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="content__tabs">
      <div class="tabs-list">
        <ul class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link :to="{ name: 'grant-participation' }" exact-active-class="tabs-list__link--active"
                         class="tabs-list__link">Участие
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link :to="{ name: 'grant-victory' }" exact-active-class="tabs-list__link--active"
                         class="tabs-list__link">Победы
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view class="content__body"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GrantLayout',
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    userRole() {
      return this.$store.state.userRole
    },
  },
  methods: {
    onExport() {
      this.$store.dispatch('grant/GET_EXPORT')
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Экспорт_участие_и_победа_в_грантовых_конкурсах_и_субсидиях.docx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
};
</script>
<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
